// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-place-[id]-js": () => import("./../../../src/pages/place/[id].js" /* webpackChunkName: "component---src-pages-place-[id]-js" */),
  "component---src-pages-sorry-js": () => import("./../../../src/pages/sorry.js" /* webpackChunkName: "component---src-pages-sorry-js" */)
}

